<template>
  <div class="container contents">
    <div class="wrap">
      <v-row class="pageIndex">
        <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12">
          <h4>광고주</h4>
          <ul>
            <li>
              <router-link to="/guide/campaign/store">매장 체험단</router-link>
            </li>
            <li>
              <router-link to="/guide/campaign/product">제품/서비스 체험단</router-link>
            </li>
            <li>
              <router-link to="/guide/campaign/press">기자단</router-link>
            </li>
          </ul>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 12">
          <h4>광고대행사</h4>
          <ul>
            <li>
              <router-link to="/guide/agency/store">매장 체험단</router-link>
            </li>
            <li>
              <router-link to="/guide/agency/product">제품/서비스 체험단</router-link>
            </li>
            <li>
              <router-link to="/guide/agency/press">기자단</router-link>
            </li>
          </ul>
        </v-col>
      </v-row>
      <h2>슈퍼차트 광고대행사 이용 가이드</h2>
      <span class="mt-4" style="color:#777;display:block">기자단 캠페인</span>
      <h3>1. 이용 방법</h3>
      <h4>
        1) 다수의 광고주 계정을 관리하는 경우<font
          style="font-weight:400!important;"
          >(다수의 계정 가입 및 마스터 계정 지정)</font
        >
      </h4>
      <p>
        연동이 완료되면 위 이미지와 같이 광고대행사 계정에서 관리하는 계정으로
        전환이 가능합니다. 마스터 계정 등록 요청은 아래 절차에 따라 진행해주시면
        됩니다.
      </p>
      <ol type="a">
        <li>
          <p>
            <a href="https://www.superchart.com" target="_blank"
              >www.superchart.com</a
            >
            접속 및 회원가입 버튼 클릭
          </p>
        </li>
        <li>
          <p>광고대행사 계정 가입(광고 대행사 정보 입력)</p>
        </li>
        <li>
          <p>광고주 계정 가입(광고주 정보 입력)</p>
        </li>
        <li>
          <p>각각의 광고주 계정에서 캠페인 등록 진행</p>
        </li>
        <li>
          <p>
            <a href="http://pf.kakao.com/_Jgyxid" target="_blank"
              >http://pf.kakao.com/_Jgyxid</a
            >
            카톡 채널로 계정 연동 요청
          </p>
        </li>
      </ol>
      <figure>
        <img src="@/assets/img/guide/main2.png" alt="슈퍼차트 메인 화면" />
      </figure>
      <h4>
        2) 하나의 광고대행사 계정에서 다수의 광고 캠페인을 직접 관리하는
        경우<font style="font-weight:400!important;"> (하나의 계정 가입)</font>
      </h4>
      <p>
        아래 절차에 따라 진행해주시면 됩니다.
      </p>
      <ol type="a">
        <li>
          <p>
            <a href="https://www.superchart.com" target="_blank"
              >www.superchart.com</a
            >
            접속 및 회원가입 버튼 클릭
          </p>
        </li>
        <li>
          <p>광고대행사 계정 가입(광고 대행사 정보 입력)</p>
        </li>
        <li>
          <p>좌측 ‘새 캠페인 추가' 버튼 클릭</p>
        </li>
        <li>
          <p>광고주의 캠페인 등록</p>
        </li>
      </ol>
      <p>
        좌측 캠페인 목록 메뉴에서 다수의 캠페인 관리가 가능합니다.
      </p>
      <figure>
        <img src="@/assets/img/guide/main1.png" alt="슈퍼차트 메인 화면" />
      </figure>
      
      <h3>2. 캠페인 등록</h3>
      <h4>1) 기본 정보 입력</h4>
      <p>
        광고 대상의 카테고리와 세부 유형을 선택해주세요.
      </p>
      <figure>
        <img src="@/assets/img/guide/regcamPress1.png" alt="슈퍼차트 캠페인 등록" />
      </figure>
      <h4>2) 상세 정보 입력</h4>
      <p>
        캠페인 상세정보를 입력해주세요. 제품과 서비스 카테고리의 경우 링크
        입력란이 있습니다. 해당 링크는 클릭수 집계가 가능한 링크로 변환되어
        블로그 콘텐츠 내에 필수로 들어가게 됩니다. 제품의 경우 제품 상세페이지
        이미지를 업로드해주세요.(1MB 이하)
      </p>
      <figure>
        <img
          src="@/assets/img/guide/regcamPress2.png"
          alt="슈퍼차트 캠페인 등록 - 상세정보"
        />
      </figure>
      <span class="caption"
        >이 단계에서는 쇼핑몰에서 사용하는 긴 상세 이미지를 업로드해주세요.
        없으실 경우 생략하셔도 좋습니다.</span
      >
      <h4>3) 소개 입력</h4>
      <p>
        회원들이 광고 제안을 받을 때 함께 보여지는 내용입니다. 매장/제품/서비스에 대한 정보를 상세하게 적어주세요.
      </p>
      <figure class="narrow">
        <img
          src="@/assets/img/guide/regcamPress3.png"
          alt="슈퍼차트 캠페인 등록 - 소개"
        />
      </figure>
      <h4 class="strong">4) 광고 정보 입력 (중요!)</h4>
      <p>광고의 조건을 입력하는, 가장 중요한 페이지입니다.</p>
      <ol type="a">
        <li>
          <h5>회원 제공 혜택</h5>
          <p>제공되는 제품/서비스의 이름과 금액을 입력해주세요.</p>
        </li>
        <li class="strong">
          <h5>필수 미션(중요)</h5>
          <p>
            가장 중요한 부분입니다. 콘텐츠 내 필수로 들어가야하는 내용들을
            입력해주세요.
          </p>
        </li>
        <li class="strong">
          <h5>필수 키워드(중요)</h5>
          <p>
            가장 중요한 부분입니다. 콘텐츠 내 필수로 들어가야하는 키워드를
            입력해주세요.<br />
            메인으로 타겟하는 키워드를 2-3개 내에서 조합해서 입력해주시면 가장
            효율적입니다.
          </p>
        </li>
        <li>
          <h5>선택 키워드</h5>
          <p>콘텐츠 내에 들어가면 좋을 키워드를 입력해주세요.</p>
          <figure>
            <img
              src="@/assets/img/guide/regcam4-1.png"
              alt="슈퍼차트 캠페인 등록 - 광고 조건"
            />
          </figure>
        </li>
        <li>
          <h5>편집 조건 설정</h5>
          <p>사진 분량, 영상 포함, 얼굴 포함 등의 조건을 설정해주세요.</p>
          <figure>
            <img
              src="@/assets/img/guide/regcam4-2.png"
              alt="슈퍼차트 캠페인 등록 - 편집 조건"
            />
          </figure>
        </li>
      </ol>
      <h4>5) 사진 업로드</h4>
      <p>
        회원들에게 광고 제안이 발송될 때 제안 내용에 보이는 매장/제품/서비스의 사진을
        올려주세요.(1MB 이하)<br />
      </p>
      <figure>
        <img
          src="@/assets/img/guide/regcam5.png"
          alt="슈퍼차트 캠페인 등록 - 사진 등록"
        />
      </figure>
      <span class="caption"
        >이 단계에서는 매장/제품/서비스의 모습을 담은 사진을 꼭 한 장 이상
        올려주세요.</span
      >
      <h4>6) 캠페인 검수 요청</h4>
      <p>
        <a href="http://pf.kakao.com/_Jgyxid" target="_blank"
          >http://pf.kakao.com/_Jgyxid</a
        >
        카톡 채널로 ‘캠페인 검수' 요청을 주세요.<br />
        잘못 등록된 정보가 없는지, 부족한 정보가 없는지 확인하고 피드백을
        드립니다.
      </p>
      <h3>3. 캠페인 진행</h3>
      <h4>1) 타겟 키워드 검색</h4>
      <p>
        원하는 키워드를 입력하면, 해당 키워드의 검색량, 상위노출 난이도, 해당
        키워드로 노출되고 있는 블로거들의 광고비용 범위를 확인할 수 있습니다.
      </p>
      <figure>
        <img src="@/assets/img/guide/search1.png" alt="슈퍼차트 캠페인 검색" />
      </figure>
      <h4>2) 블로거 검색</h4>
      <p>해당 키워드로 상위노출 가능성이 높은 블로거를 추천해드립니다.</p>
      <figure>
        <img src="@/assets/img/guide/search2.png" alt="슈퍼차트 캠페인 검색" />
      </figure>
      <h4>3) 필터 설정</h4>
      <ol type="a">
        <li>
          <h5>카테고리 필터</h5>
          <p>
            특정 카테고리의 상위노출 비중이 높은 블로거 순으로 정렬이
            가능합니다.
          </p>
        </li>
        <li>
          <h5>성별, 활동지역, 연령, 상위노출 가능성, 단가, 추천순 필터</h5>
          <p>필요에 따라 특정 조건에 해당하는 블로거를 찾을 수 있습니다.</p>
        </li>
        <li>
          <h5>즐겨찾기 필터</h5>
          <p>즐겨찾기 버튼을 누른 블로거만 모아서 볼 수 있습니다.</p>
        </li>
      </ol>
      <figure>
        <img src="@/assets/img/guide/search3.png" alt="슈퍼차트 캠페인 검색" />
      </figure>
      <h4>4) 블로거 영향력 확인</h4>
      <p>블로거의 레퍼런스, 영향력 지표들을 확인합니다.</p>
      <figure>
        <img src="@/assets/img/guide/detail.png" alt="슈퍼차트 캠페인 검색" />
      </figure>
      <h4>5) 원하는 블로거 선택</h4>
      <p>원하는 블로거를 선택한 후 제안 목록에 추가합니다.</p>
      <figure>
        <img src="@/assets/img/guide/search5.png" alt="슈퍼차트 캠페인 검색" />
      </figure>
      <h4>6) 제안 금액 설정</h4>
      <p>
        블로거를 선택한 후 제안 금액을 직접 입력하실 수 있고, ‘금액 일괄 수정'
        버튼을 누르면 예상광고단가의 70~200% 까지 일괄 수정이 가능합니다
      </p>
      <figure>
        <img src="@/assets/img/guide/search6.png" alt="슈퍼차트 캠페인 검색" />
      </figure>
      <h4>7) 광고 제안하기</h4>
      <ol type="a">
        <li>
          <p>
            제안 목록에 추가된 블로거들 중 광고제안을 할 블로거들을 선택하여
            하단 제안하기 버튼을 누르면 광고 제안이 진행됩니다.
          </p>
        </li>
        <li>
          <p>
            광고 제안은 12시간 이내 수락/거절이 진행되며, 충전금액은 블로거가
            제안을 수락했을 때 해당 금액만큼 소진됩니다.
          </p>
        </li>
      </ol>
      
      <h3>4.캠페인 진행</h3>
      <h4>
        1) 인플루언서의 캠페인 수락 직후 필요사항
      </h4>
      <p>
        인플루언서가 수락하고 난 후, 해당 인플루언서가 볼 수 있는 리뷰 가이드의 링크를 등록할 수 있습니다.<br>
        캠페인 관리 페이지의 신청정보 테이블에서 해당 인플루언서 선택 후 리뷰 가이드 링크를 등록해주세요.<br>
        링크가 입력된 후 인플루언서에게 알림이 전송되어 원고/리뷰 작성을 시작할 수 있습니다.
      </p>
      <h4>
        2)등록된 원고/리뷰 확인
      </h4>
      <ol type="a">
        <li>
          <h5>수정횟수 0회일 경우</h5>
          <p>
            유저가 원고등록 과정 없이 리뷰를 바로 등록합니다. 리뷰를 확인한 뒤, 평가 점수를 매길 수 있습니다.
          </p>
        </li>
        <li>
          <h5>수정횟수 1회 이상일 경우</h5>
          <p>
            인플루언서가 원고를 먼저 등록합니다. SNS에 게시물을 업로드 하기 전, 내용을 확인하기 위한 절차입니다.
            만일 등록된 원고에 수정을 원하는 부분이 있으실 경우, 필요한 부분을 메시지에 적어 수정요청을 발송해주세요. 
          </p>
          <figure class="narrow">
            <img src="@/assets/img/guide/request.png" alt="슈퍼차트 원고/리뷰 요청" />
          </figure>
          <p>
            수정된 원고가 등록되면 연락처를 통해 문자 안내를 드립니다.<br>
            확인한 원고에 이상이 없다면 요청 버튼 > 업로드 요청을 메시지와 함께 보내주세요. 인플루언서가 SNS에 원고의 내용으로 게시물을 업로드합니다.<br>
            원고가 등록된 이후 5일간 요청이 없을 경우, 인플루언서에게 자동으로 리뷰 업로드 요청이 발송됩니다.
          </p>
        </li>
      </ol>
      
    </div>
  </div>
</template>
<script>
export default {
  name: "Agency3",
  data() {
    return {
      tab: null,
      };
  },
};
</script>

<style scoped lang="scss">
.help-contents {
  padding: 80px 0;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

h2 {
  font-size: 2.25em;
  padding-top: 1.6em;
}

h3 {
  font-size: 1.5em;
  padding-top: 8em;
  padding-bottom: 0.4em;
  border-bottom: solid 1px #dbdbdb;
}

h4 {
  font-size: 1.25em;
  padding-top: 2em;
  padding-bottom: 1em;
}

h5 {
  font-size: 1em;
  line-height: 1.8em;
  padding-top: 1em;
  margin-bottom: 0;
}

p {
  font-size: 1em;
  line-height: 2em;
  margin: 0 0 0.4em 0;
}

figure {
  width: 100%;
  max-width: 700px;
  margin: 1em 0;
  border: 1px solid #efefef;
}

figure.narrow {
  max-width: 70vw;
}

figure img {
  width: 100%;
}

ol * {
  line-height: 2em;
  margin-bottom: 0.25em;
}

a {
  display: block;
  font-size: 1.125em;
  font-weight: 500;
  line-height: 2.4em;
  color: #1e88e5;
  text-decoration: underline;
}

.caption {
  font-size: 1em !important;
  color: #78909c;
}

.container {
  max-width: 720px;
}

.wrap {
  width: 100%;
  margin: 0;
  padding: 0 2em 20em 2em;
  color: #333333;
}

.wrap * {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.strong {
  color: #ff3d00;
}

.pageIndex ul {
  padding: 0;
  list-style-type: none;
}

.pageIndex {
  padding-bottom: 0.8em;
  border-bottom: solid 1px #dbdbdb;
}

</style>
